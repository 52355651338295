/* eslint-disable jsx-a11y/label-has-associated-control */
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import toast from 'react-hot-toast';
import { AiFillCaretDown } from 'react-icons/ai';
import InputMask from 'react-input-mask';
import MultipleSelect, { components } from 'react-select';
import * as Yup from 'yup';
import ConsentArea from '../../components/ConsentArea/ConsentArea';
import FieldError from '../../components/FieldError';
import ResponsibleRadio from '../../components/ResponsibleRadio';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import Alert from '../../components/ui/alert';
import Anchor from '../../components/ui/Anchor';
import Form, { FormGroup, Input, Select } from '../../components/ui/form';
import Label from '../../components/ui/form/label/label';
import Heading from '../../components/ui/Heading';
import SectionTitle from '../../components/ui/section-title';
import Text from '../../components/ui/Text';
import { Col, Row } from '../../components/ui/Wrapper';
import useFeedbackModal from '../../hooks/useFeedbackModal';
import useFormData from '../../hooks/useFormData';
import contactService from '../../services/contactService';
import scholarshipService from '../../services/scholarshipService';
import consentTypes from '../../utils/constants/consentTypes';
import customerTypes from '../../utils/constants/customerTypes';

const schemaValues = {
  unit: Yup.number().required('Campo obrigatório'),
  segment: Yup.number().required('Campo obrigatório'),
  scholarship_agenda_id: Yup.number().required('Campo obrigatório'),
  type: Yup.string()
    .matches(/PARENT|STUDENT/g)
    .required('Informe se você é o aluno ou seu responsável'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  cell_phone: Yup.string().required('Campo obrigatório'),
  birthday: Yup.string().required('Campo obrigatório'),
  phone: Yup.string(),
  knowledge_source_id: Yup.number().required('Campo obrigatório'),
  name: Yup.string().required('Campo obrigatório'),
  parent_name: Yup.string().when('type', {
    is: 'PARENT',
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string(),
  }),
  zip_code: Yup.string().required('Campo obrigatório'),
  public_tender_ids: Yup.array().of(Yup.number()).required('Campo obrigatório'),
};

const consentValidationMessage = 'Você deve consentir com este item para continuar';

const schema = Yup.object().shape(schemaValues);

const trackFacebookPixelEvent = (name, value) => {
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('trackCustom', name, value);
    }
  }
};

const getNameById = (list, idToFind) => list.find(({ id }) => id.toString() === idToFind.toString())?.name || '';

const BolsaoForm = ({ scholarshipYear, marketingCampaigns: { utmSource, utmMedium, utmCampaign } }) => {
  const { units, knowledgeSources, segments, isLoading, showLoading, hideLoading } = useFormData();
  const [agendas, setAgendas] = useState({ hasError: false, data: [] });
  const [publicTenders, setPublicTenders] = useState([]);
  const [unitInfo, setUnitInfo] = useState(null);
  const [validationSchema, setValidationSchema] = useState(schema);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { FeedbackModal, openModal: openFeedbackModal } = useFeedbackModal();
  const { handleSubmit, setFieldValue, getFieldProps, values, errors, touched, submitCount, resetForm } = useFormik({
    initialValues: {
      campaign_source: utmSource,
      campaign_medium: utmMedium,
      campaign_name: utmCampaign,
    },
    validationSchema,
    onSubmit: async data => {
      showLoading();
      try {
        const reCaptchaToken = await executeRecaptcha('bolsao_form');
        await scholarshipService.sendSubscription(
          {
            ...data,
            birthday: format(parse(data.birthday, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
            campaign_source: utmSource,
            campaign_medium: utmMedium,
            campaign_name: utmCampaign,
            consents: Object.entries(data.consents).map(([key, value]) => ({ code: key, answer: value })),
          },
          reCaptchaToken
        );

        openFeedbackModal();

        const publicTenderIds = data.public_tender_ids.map(id => id.toString());

        trackFacebookPixelEvent('Scholarship', {
          su: getNameById(units, data.unit),
          cs: getNameById(segments, data.segment),
          pt: publicTenders
            .filter(({ value }) => publicTenderIds.includes(value.toString()))
            .map(({ label }) => label)
            .join(', '),
          ks: getNameById(knowledgeSources, data.knowledge_source_id),
        });

        resetForm();
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Aconteceu um erro inesperado. Tente novamente mais tarde');
      } finally {
        hideLoading();
      }
    },
  });

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <AiFillCaretDown />
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: '#f8f8f8',
      borderWidth: 0,
      paddingLeft: 20,
      minHeight: 56,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      paddingRight: 10,
    }),
  };

  const loadAgendas = async (schoolUnitId, segmentId) => {
    try {
      const { data } = await scholarshipService.agendas(schoolUnitId, segmentId);

      setAgendas({ data, hasError: data.length === 0 });
    } catch (e) {
      setAgendas({ data: [], hasError: true });
      console.error(e);
    }
  };

  const loadPublicTenders = async segmentId => {
    try {
      const { data } = await contactService.publicTenders(segmentId);

      setPublicTenders(data.map(({ id, name, initials }) => ({ value: id, label: `${initials} - ${name}` })));
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelectPublicTenders = tenders => {
    const ids = (tenders || []).map(({ value }) => value);
    setFieldValue('public_tender_ids', ids);
  };

  const handleChangeUnit = async ({ target: { value } }) => {
    const unit = units.find(({ id }) => id.toString() === value.toString());
    setUnitInfo(unit);
    setFieldValue('unit', value);

    trackFacebookPixelEvent('SU', { name: unit?.name });

    if (values.segment !== undefined) {
      await loadAgendas(value, values.segment);
    }
  };

  const handleChangeSegment = async ({ target: { value: segmentId } }) => {
    const schoolUnitId = values.unit;
    const segmentName = getNameById(segments, segmentId);

    setFieldValue('segment', segmentId);
    trackFacebookPixelEvent('CS', { name: segmentName });

    await loadAgendas(schoolUnitId, segmentId);
    await loadPublicTenders(segmentId);

    hideLoading();
  };

  const handleChangeType = ({ target: { value: type } }) => {
    setFieldValue('type', type);
  };

  const handleLoadConsent = (purposes = []) => {
    const newSchema = Yup.object().shape({
      ...schemaValues,
      consents: Yup.object().shape(
        purposes.reduce(
          (acc, { code, required }) => ({
            ...acc,
            [code]: required
              ? Yup.bool().oneOf([true], consentValidationMessage).required(consentValidationMessage)
              : Yup.bool(),
          }),
          {}
        )
      ),
    });

    setValidationSchema(newSchema);
  };

  const isParent = useMemo(() => values.type === customerTypes.PARENT, [values.type]);

  return (
    <>
      <FeedbackModal title="Inscrição concluída!">
        <p>
          A sua inscrição para a <strong>Prova Bolsa</strong> foi concluída. Em alguns minutos você receberá a
          confirmação por e-mail. Caso isso não ocorra, verifique sua caixa de <strong>spam</strong>.
        </p>
      </FeedbackModal>
      <Form onSubmit={handleSubmit}>
        <SectionTitle align="left" title={`Bolsão ${scholarshipYear}`} />
        <Heading as="h6" mb="30px" fontweight={500} letterspacing="2px" texttransform="uppercase" color="info">
          Concursos Militares e Vestibulares
        </Heading>
        <Text mb="30px">
          Para garantir sua inscrição siga os passos abaixo, primeiramente escolhendo sua Unidade de preferência. Boa
          sorte!
        </Text>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="unit" required>
                Unidade
              </Label>
              <Select {...getFieldProps('unit')} name="unit" id="unit" onChange={handleChangeUnit}>
                <option value="">Selecione uma unidade</option>
                {units?.map(({ id, name }) => (
                  <option value={id}>{name}</option>
                ))}
              </Select>
              <FieldError name="unit" errors={errors} touched={touched} submitCount={submitCount} />
              {unitInfo?.formatted_address ? (
                <Text>
                  {unitInfo.formatted_address} (
                  <Anchor
                    color="secondary"
                    target="_blank"
                    path={`https://www.google.com/maps/dir/Current+Location/${encodeURIComponent(
                      unitInfo.formatted_address
                    )}`}
                  >
                    Rotas via Google Maps
                  </Anchor>
                  )
                </Text>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        {values.unit ? (
          <Row>
            <Col lg={12}>
              <FormGroup mb="20px">
                <Label name="segment" required>
                  Turma
                </Label>
                <Select {...getFieldProps('segment')} name="segment" id="segment" onChange={handleChangeSegment}>
                  <option value="">Selecione uma turma</option>
                  {segments?.map(({ id, name }) => (
                    <option value={id}>{name}</option>
                  ))}
                </Select>
                <FieldError name="segment" errors={errors} touched={touched} submitCount={submitCount} />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        {values.unit && values.segment && !!agendas.data?.length ? (
          <Row>
            <Col lg={12}>
              <FormGroup mb="20px">
                <Label name="scholarship_agenda_id" required>
                  Data e hora
                </Label>
                <Select
                  {...getFieldProps('scholarship_agenda_id')}
                  name="scholarship_agenda_id"
                  id="scholarship_agenda_id"
                >
                  <option value="">Selecione uma data e hora</option>
                  {agendas.data?.map(({ id, date_time_formatted }) => (
                    <option value={id}>{date_time_formatted}</option>
                  ))}
                </Select>
                <FieldError name="scholarship_agenda_id" errors={errors} touched={touched} submitCount={submitCount} />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        {values.unit && values.segment && agendas.hasError ? (
          <Alert variant="warning">
            <>
              <Text mb="0" fontWeight="bold">
                Não existem agendas disponíveis para a turma escolhida. Contate-nos para maiores informações.
              </Text>
              <Text mb="0" fontWeight="bold">
                Telefone:
                <Text as="span" mb="0" fontWeight="regular">
                  {` ${unitInfo?.cell_phone} (`}
                </Text>
                <Text as="span" mb="0" fontWeight="regular">
                  <Anchor
                    color="info"
                    fontWeight="regular"
                    path={`https://api.whatsapp.com/send?phone=${unitInfo?.cell_phone.replace(/\D+/g, '')}`}
                  >
                    WhatsApp
                  </Anchor>
                  {`) `}
                </Text>
              </Text>
              <Text mb="0" fontWeight="bold">
                {`E-mail: `}
                <Text as="span" mb="0" fontWeight="regular">
                  {unitInfo?.email}
                </Text>
              </Text>
            </>
          </Alert>
        ) : null}
        {values.scholarship_agenda_id ? <ResponsibleRadio onChange={handleChangeType} /> : null}
        {values.type ? (
          <>
            <Row>
              <Col lg={12}>
                <FormGroup mb="20px">
                  <Label name="name" description={isParent ? 'Aluno' : null} required>
                    Nome
                  </Label>
                  <Input {...getFieldProps('name')} type="text" name="name" id="name" placeholder="Informe o nome" />
                  <FieldError name="name" errors={errors} touched={touched} submitCount={submitCount} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup mb="20px">
                  <Label name="name" description={isParent ? 'Aluno' : null} required>
                    Data de nascimento
                  </Label>
                  <InputMask {...getFieldProps('birthday')} mask="99/99/9999" maskChar={null}>
                    {inputProps => (
                      <Input
                        {...inputProps}
                        type="text"
                        name="birthday"
                        id="birthday"
                        placeholder="Informe a data de nascimento"
                      />
                    )}
                  </InputMask>
                  <FieldError name="birthday" errors={errors} touched={touched} submitCount={submitCount} />
                </FormGroup>
              </Col>
            </Row>
            {isParent ? (
              <Row>
                <Col lg={12}>
                  <FormGroup mb="20px">
                    <Label name="parent_name" description={isParent ? 'Responsável' : null} required>
                      Nome
                    </Label>
                    <Input
                      {...getFieldProps('parent_name')}
                      type="text"
                      name="parent_name"
                      id="parent_name"
                      placeholder="Informe o nome"
                    />
                    <FieldError name="parent_name" errors={errors} touched={touched} submitCount={submitCount} />
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col lg={12}>
                <FormGroup mb="20px">
                  <Label name="name" description={isParent ? 'Responsável' : null} required>
                    E-mail
                  </Label>
                  <Input
                    {...getFieldProps('email')}
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Informe o e-mail"
                  />
                  <FieldError name="email" errors={errors} touched={touched} submitCount={submitCount} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup mb="20px">
                  <Label name="name">Telefone</Label>
                  <InputMask
                    {...getFieldProps('phone')}
                    mask="(99) 9999-9999"
                    description={isParent ? 'Responsável' : null}
                    maskChar={null}
                  >
                    {inputProps => (
                      <Input {...inputProps} type="text" name="phone" id="phone" placeholder="Informe o telefone" />
                    )}
                  </InputMask>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup mb="20px">
                  <Label name="cell_phone" description={isParent ? 'Responsável' : null} required>
                    Celular
                  </Label>
                  <InputMask {...getFieldProps('cell_phone')} mask="(99) 99999-9999" maskChar={null}>
                    {inputProps => (
                      <Input
                        {...inputProps}
                        type="text"
                        name="cell_phone"
                        id="cell_phone"
                        placeholder="Informe o telefone celular"
                      />
                    )}
                  </InputMask>
                  <FieldError name="cell_phone" errors={errors} touched={touched} submitCount={submitCount} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup mb="20px">
                  <Label name="zip_code" required>
                    CEP
                  </Label>
                  <InputMask {...getFieldProps('zip_code')} mask="99999-999" maskChar={null}>
                    {inputProps => (
                      <Input {...inputProps} type="text" name="zip_code" id="zip_code" placeholder="Informe o CEP" />
                    )}
                  </InputMask>
                  <FieldError name="zip_code" errors={errors} touched={touched} submitCount={submitCount} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup mb="20px">
                  <Label name="knowledge_source_id" required>
                    Como nos conheceu?
                  </Label>
                  <Select {...getFieldProps('knowledge_source_id')} name="knowledge_source_id" id="knowledge_source_id">
                    <option value="">Selecione uma opção</option>
                    {knowledgeSources?.map(({ id, name }) => (
                      <option value={id}>{name}</option>
                    ))}
                  </Select>
                  <FieldError name="knowledge_source_id" errors={errors} touched={touched} submitCount={submitCount} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup mb="20px">
                  <Label name="public_tender_ids" className="multi-select" required>
                    Concurso(s) de interesse(s)
                  </Label>
                  <MultipleSelect
                    placeholder="Selecione seus concursos de interesse"
                    components={{ DropdownIndicator }}
                    options={publicTenders}
                    styles={customStyles}
                    onChange={handleSelectPublicTenders}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                  <FieldError name="public_tender_ids" errors={errors} touched={touched} submitCount={submitCount} />
                </FormGroup>
              </Col>
            </Row>
          </>
        ) : null}
        <ConsentArea
          type={consentTypes.SCHOLARSHIP}
          onLoad={handleLoadConsent}
          getFieldProps={getFieldProps}
          errors={errors}
          touched={touched}
          submitCount={submitCount}
        />
        <Row>
          <Col lg={12}>
            <SubmitButton isLoading={isLoading} />
          </Col>
        </Row>
      </Form>
    </>
  );
};

BolsaoForm.propTypes = {
  scholarshipYear: PropTypes.number.isRequired,
  marketingCampaigns: PropTypes.shape({
    utmSource: PropTypes.string,
    utmMedium: PropTypes.string,
    utmCampaign: PropTypes.string,
  }),
};

BolsaoForm.defaultProps = {
  marketingCampaigns: {
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
  },
};

export default BolsaoForm;

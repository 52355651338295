import api from './api';

const BASE_URL = '/scholarships';

const agendas = (schoolUnitId, segmentId) =>
  api.get(`${BASE_URL}/agendas`, { params: { school_unit_id: schoolUnitId, segment_id: segmentId, mock: false } });

const sendSubscription = (data, reCaptchaToken) =>
  api.post(
    `${BASE_URL}/v2/subscriptions`,
    { ...data },
    {
      headers: {
        'g-recaptcha': reCaptchaToken,
      },
      params: {
        mock: false,
      },
    }
  );

export default {
  agendas,
  sendSubscription,
};
